import React from "react";
import PageLayout from "../components/PageLayout";
import Tabs from "../components/Tabs";
import parse from "html-react-parser";

function Home(data: IAppData) {
  const {
    pages,
    homePage: { headline, introductionText, tabs },
  } = data;

  return (
    <PageLayout {...data} type="home">
      <>
        <p>{parse(introductionText)}</p>
        <h1>{headline}</h1>
        <Tabs tabs={tabs} content={pages} />
      </>
    </PageLayout>
  );
}

export default Home;
