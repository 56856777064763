import React, { createContext, useReducer } from "react";

export const SET_BACKDROP = "SET_BACKDROP";
export const SET_DATA = "SET_DATA";

interface IStateData {
  backdrop: boolean;
  data: IAppData | null;
}

interface IContext {
  state: IStateData;
  dispatch: any;
}

interface IAction {}

const initialState = {
  backdrop: false,
  data: null,
};

const store = createContext<IContext>({
  state: initialState,
  dispatch: () => {},
});
const { Provider } = store;

const StateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer((state: IStateData, action: any) => {
    switch (action.type) {
      case SET_DATA:
        return {
          ...state,
          data: action.payload,
        };
      case SET_BACKDROP:
        return {
          ...state,
          backdrop: action.payload,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
