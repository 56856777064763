import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import Image from "./Image";
import { shuffle } from "../utils/shuffle";
import { useMediaQuery } from "@mui/material";

export const PER_PAGE = {
  mobile: 6,
  desktop: 12,
};

function Tabs({ tabs, content }: ITabs) {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const firstCards = content.slice(0, 9);
  const shuffledCards = shuffle(content.slice(10));

  const [activeContent, setActiveContent] = useState<Array<IPage>>([
    ...firstCards,
    ...shuffledCards,
  ]);

  const isMobile = useMediaQuery("(max-width:599px)");

  const [pagination, setPagination] = useState<number>(
    isMobile ? PER_PAGE.mobile : PER_PAGE.desktop
  );

  const filterCards = (filter: string | null) => {
    setPagination(isMobile ? PER_PAGE.mobile : PER_PAGE.desktop);
    const filteredContent = filter
      ? content.filter(
          (card) => card.museum === filter || card.exhibition === filter
        )
      : content;
    setActiveContent(filteredContent);
    setActiveTab(filter);
  };

  const handleClick = (filter: string | null) => filterCards(filter);

  const handleLoadMore = () => {
    setPagination(pagination + (isMobile ? PER_PAGE.mobile : PER_PAGE.desktop));
  };

  return (
    <div className="tabs">
      <nav>
        {tabs.map((tab, index) => (
          <button
            className={
              tab.filter === activeTab
                ? "button-secondary-black"
                : "button-secondary-white"
            }
            key={tab.tabButton + index}
            onClick={() => handleClick(tab.filter)}
          >
            {tab.tabButton}
          </button>
        ))}
      </nav>
      <section className="tabs__content">
        {activeContent.slice(0, pagination).map((card, index) => (
          <Link to={`/${card.museum}/${card.id}`} key={card.object.name}>
            <Card variant="home" key={card.object.name + index}>
              <p>{card.object.title}</p>
              <Image
                path={`/assets/images/preview-${card.id}.png`}
                alt={card.object.name}
              />
            </Card>
          </Link>
        ))}
      </section>
      {activeContent.length > pagination && (
        <button className="button-primary" onClick={handleLoadMore}>
          Mehr laden
        </button>
      )}
    </div>
  );
}

export default Tabs;
