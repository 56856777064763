import "@google/model-viewer";
import React, { ReactNode } from "react";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": any;
    }
  }
}

interface IModelViewer extends IModel {
  children: ReactNode;
}

const DefaultModelViewer = ({ url, children }: IModelViewer) => {
  return (
    <div className="model-viewer-wrapper">
      <model-viewer
        src={url}
        poster=""
        alt=""
        ar
        ar-modes="webxr scene-viewer quick-look"
        environment-image="neutral"
        auto-rotate
        camera-controls
        async
      >
        <button slot="ar-button" id="custom-ar-button">
          Starte AR
        </button>
        {children}
      </model-viewer>
    </div>
  );
};

export default DefaultModelViewer;
