import React from "react";

type FooterProps = IFooter & {
  variant: "home" | "detail" | "overview" | "notfound";
};

function Footer({ links, variant = "detail" }: FooterProps) {
  return (
    <footer className={`footer footer-${variant}`}>
      <a
        href="https://nextmuseum.io"
        target="_self"
        rel="noopener noreferrer"
        className="footer-logo"
      >
        <img src="/footer-logo-small.png" alt="https://nextmuseum.io" />
        <span>nextmuseum.io</span>
      </a>
      <div className="footer-links">
        {links.map((link) => (
          <a
            href={link.url}
            title={link.title}
            key={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
        ))}
      </div>
    </footer>
  );
}

export default Footer;
