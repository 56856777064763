import {ReactChild} from "react";

type TooltipProps = {
    title: string;
    children: ReactChild;
    visible: boolean;
}

const Tooltip = ({ children, title, visible }: TooltipProps) => {
    return (
        <div className="tooltip">
            {children}
            {visible && <span className="tooltip-text">{title}</span>}
        </div>
    );
};

export default Tooltip;
