import React, { ReactChild } from "react";
import Footer from "./Footer";
import Header from "./Header";

interface IPageLayout {
  header: IHeader;
  footer: IFooter;
  pages: Array<IPage>;
  exhibitionLink?: ILink | undefined;
  children: ReactChild;
  type: "home" | "detail" | "overview" | "notfound";
}

function PageLayout({
  header,
  footer,
  pages,
  type,
  exhibitionLink,
  children,
}: IPageLayout) {
  return (
    <div className={`App ${type}`}>
      <Header pages={pages} {...header} exhibitionLink={exhibitionLink} />
      <main>{children}</main>
      <Footer {...footer} variant={type} />
    </div>
  );
}

export default PageLayout;
