import React from "react";

function Logo({ url, text, title, type }: ILogo) {
  return (
    <a className="logo" href={url}>
        {type === "header" ?
            <span className="logo-link">{text}</span> : <img
            src="/logo-small.svg"
            alt={title}
        />
        }
    </a>
  );
}

export default Logo;
