import React, {useState} from 'react';
import { Skeleton } from "@mui/material";

function Image({ path, alt }: any) {
    const [showSkeleton, setSkeleton] = useState<boolean>(false);

    return (
        showSkeleton ? <Skeleton variant="rectangular" width={'calc(100% - 2rem)'} height={'calc(100% - 2rem)'} /> :
        <img src={path} alt={alt} onErrorCapture={() => { setSkeleton(true); }} />
    )
}

export default Image;
