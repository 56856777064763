import React from "react";
import Card from "./Card";
import parse from "html-react-parser";

function Tutorial({ headline, steps }: ITutorial) {
  return (
    <div className="tutorial">
      <h2>{headline}</h2>
      <div className="tutorial__wrapper">
        {steps.map((step, index) => (
          <Card key={step.title}>
            <h3>{step.title}</h3>
            <p>{parse(step.text)}</p>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Tutorial;
