import React from 'react';

function Card({variant = "simple", children}: any) {
    return (
        <div className={`card card--${variant}`}>
            {children}
        </div>);
}

export default Card;
