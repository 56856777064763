import React from 'react';

function PopupContent({ object: { name, meta, description } }: IPage) {
    return (
        <div className="popup-container">
            <h3>{name}</h3>
            {meta.map((item, index) => <span key={item.concat(index.toString())}>{item}</span>
            )}
            <p>{description}</p>
        </div>);
}

export default PopupContent;
