import React, { useState } from "react";
import DefaultModelViewer from "../components/ModelViewer";
import CircleButton from "../components/CircleButton";
import Backdrop from "../components/Backdrop";
import Popup from "../components/Popup";
import ScanMe from "../components/ScanMe";
import PopupContent from "../components/PopupContent";
import Tutorial from "../components/Tutorial";
import PageLayout from "../components/PageLayout";
import { useLocation } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import { useMediaQuery } from "@mui/material";
import getBrowser from "../utils/detectBrowser";
import BrowserFallback from "../components/BrowserFallback";

function DetailPage({ url, data }: { url: string; data: IAppData }) {
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:599px)");
  const browser = getBrowser();

  let location = useLocation();
  const currentRoute = location.pathname.split("/")[2];
  const page = data.pages.find(
    (page) => page.id === currentRoute && page.museum === url
  );

  if (!page) {
    return <NotFoundPage {...data} />;
  }

  console.log(browser.name, parseFloat(browser.version));

  return (
    <PageLayout
      {...data}
      type="detail"
      exhibitionLink={page.exhibitionLink || undefined}
    >
      <>
        <ScanMe id={page.id} />
        {browser.name === "Safari" && parseFloat(browser.version) < 14 ? (
          <BrowserFallback
            id={page.id}
            name="test"
            browser="Safari 14 oder höher"
          />
        ) : (
          <DefaultModelViewer {...page.model}>
            {isMobile ? (
              <CircleButton handleClick={() => setOpen(true)} />
            ) : (
              <></>
            )}
          </DefaultModelViewer>
        )}
        <Popup open={open} handleClick={() => setOpen(false)}>
          <PopupContent {...page} />
        </Popup>
        {!isMobile && <CircleButton handleClick={() => setOpen(true)} />}
        <Backdrop>
          <Tutorial {...data.tutorial} />
        </Backdrop>
      </>
    </PageLayout>
  );
}

export default DetailPage;
