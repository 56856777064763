import React, { useEffect, useState } from "react";
import "./App.scss";
import { StateProvider } from "./components/Context/store";
import DetailPage from "./pages/DetailPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";

function App({ lang = "de" }) {
  const [data, setData] = useState<IAppData | null>(null);

  useEffect(() => {
    fetch(`/data/${lang}.json`)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error(res.status + "something went wrong");
        }
      })
      .then((result) => {
        setData(result as IAppData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!data) {
    return <></>;
  }

  return (
    <StateProvider>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home {...data} />} />
            <Route
              path="/m-u/*"
              element={<DetailPage data={data} url="m-u" />}
            />
            <Route
              path="/k-p/*"
              element={<DetailPage data={data} url="k-p" />}
            />
            <Route path="*" element={<NotFoundPage {...data} />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
