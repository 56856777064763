import React, { useEffect, useRef, useState } from "react";
import Logo from "./Logo";

type HeaderProps = IHeader & {
  pages: Array<IPage>;
  exhibitionLink?: ILink | undefined;
};

function Header({ pages, logo, exhibitionLink }: HeaderProps) {
  const [fadeOut, setFadeOut] = useState(false);
  const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("scroll", handleFadeIn);
    window.addEventListener("touchend", handleFadeIn);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleFadeIn);
      window.removeEventListener("scroll", handleFadeIn);
    };
  }, []);

  const handleTouchStart = (e: TouchEvent) => {
    if (e.target === document.querySelector("model-viewer")) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setFadeOut(true);
    }
  };

  const handleFadeIn = (e: any) => {
    if (e.target !== document.querySelector("model-viewer")) {
      setFadeOut(false);
      return;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => setFadeOut(false), 2000);
  };

  return (
    <header className={`header${fadeOut ? " header--fadeout" : ""}`}>
      <Logo {...logo} />
      {exhibitionLink && (
        <span>
          <a
            className="exhibition-link"
            href={exhibitionLink.url}
            title={exhibitionLink.title}
            target="_blank"
            rel="noopener"
          >
            {exhibitionLink.text}
          </a>
        </span>
      )}
    </header>
  );
}

export default Header;
