import React, { useState } from "react";
import Image from "./Image";

const BrowserFallback = ({ id, name, browser }: any) => {
  const [close, setClose] = useState(false);
  return (
    <div className="browser-fallback">
      {!close && (
        <span className="disclaimer">
          <button
            className="button-secondary-black"
            onClick={() => setClose(true)}
          >
            schließen
          </button>
          <h3>Diese Funktion wird in deinem Browser nicht unterstützt</h3>
          <p>Bitte aktualisiere den Browser auf {browser}</p>
        </span>
      )}
      <div>
        <Image path={`/assets/images/preview-${id}.png`} alt={name} />
      </div>
    </div>
  );
};

export default BrowserFallback;
