import React, { useEffect, useRef} from 'react';

function CircleButton({handleClick}: any) {
    const buttonRef = useRef<HTMLButtonElement>(null);

    return (<><button ref={buttonRef} className="circle-button"
                      onClick={handleClick}>
    </button></>);
}

export default CircleButton;
