import React, { ReactChild } from "react";

type PopupProps = {
  open: boolean;
  handleClick: () => void;
  children: ReactChild;
};
function Popup({ open, handleClick, children }: PopupProps) {
  return (
    <div className={open ? "popup" : "popup popup--hidden"}>
      {children}
      <div className="popup-wrapper">
        <button className="button-secondary-black" onClick={handleClick}>
          Schliessen
        </button>
      </div>
    </div>
  );
}

export default Popup;
