import React from "react";
import PageLayout from "../components/PageLayout";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function NotFoundPage(data: IAppData) {
  const renderLinks = (museum: string) => {
    const links = data.pages.filter(
      (page) => page.museum === museum && page.public
    );
    return links.map((page) => (
      <Link to={`/${page.museum}/${page.id}`} key={page.object.name}>
        <ArrowForwardIcon />
        {page.object.name || page.id}
      </Link>
    ));
  };

  return (
    <PageLayout {...data} type="notfound">
      <>
        <h1>Diese Seite existiert nicht</h1>
        <h3>Hier geht's zu den Objekten...</h3>
        <div className="link-wrapper">
          <h3>...vom Museum Ulm</h3>
          {renderLinks("m-u")}
        </div>
        <div className="link-wrapper">
          <h3>...vom Kunstpalast</h3>
          {renderLinks("k-p")}
        </div>
      </>
    </PageLayout>
  );
}

export default NotFoundPage;
