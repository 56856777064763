import { useState } from "react";
import Tooltip from "./Tooltip";

const CopyLink = () => {
  const [visible, setVisible] = useState(false);
  const copyUrlToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        console.log("success");
        setVisible(true);
        setTimeout(() => setVisible(false), 2000);
      })
      .catch(() => {
        console.log("failure");
      });
  };

  return (
    <Tooltip title="Link kopiert!" visible={visible}>
      <button className="button-secondary-white" onClick={copyUrlToClipboard}>
        <span>Copy to share</span>
      </button>
    </Tooltip>
  );
};

export default CopyLink;
