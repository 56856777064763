import React, { useContext } from "react";
import CopyLink from "./CopyLink";
import { SET_BACKDROP, store } from "./Context/store";
import Tooltip from "./Tooltip";

type ScanMeProps = {
  id: string;
};

function ScanMe({ id }: ScanMeProps) {
  const {
    state: { backdrop },
    dispatch,
  } = useContext(store);
  const handleOpen = () => {
    dispatch({ type: SET_BACKDROP, payload: true });
  };
  return (
    <div className="scan-me">
      <Tooltip title="Augmented Reality (AR) Modus" visible={true}>
        <div className="scan-me__wrapper">
          <img src={`/assets/qrcodes/qr-code-${id}.svg`} alt="qrcode" />
        </div>
      </Tooltip>
      <div>
        <CopyLink />
        <button className="button-secondary-white" onClick={handleOpen}>
          How to AR
        </button>
      </div>
    </div>
  );
}

export default ScanMe;
