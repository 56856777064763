import React, {useContext, useEffect} from 'react';
import {SET_BACKDROP, store} from "./Context/store";

function Backdrop({children}: any) {
    const {state: { backdrop }, dispatch} = useContext(store);
    const handleClose = () => {
        dispatch({ type: SET_BACKDROP, payload: false });
    };
    return (backdrop ? <div className="backdrop">
        <button className="button-secondary-black" onClick={handleClose}>Schliessen</button>
        {children}
    </div> : <></>);
}

export default Backdrop;
